import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  visible: boolean;

  constructor() {/* this.visible = false;*/ this.hide()  }

  hide() {
    this.visible = false;
    //console.log(this.visible)
  }

  show() {
    this.visible = true;
    //console.log(this.visible)

  }

  toggle() { this.visible = !this.visible; }
}
