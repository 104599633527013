export class ExamListIn {
    BatchId: number;
    OrgGroupId: number;
    TokenKey: string;
    UserId: number;
    UserSourceId: number;
    UserSourceTypeId: number;
    FromDate: string;
    ToDate: string;
  }
  export class ExamListOut {
    StatusCode: number;
    Message: number;
    AvailableTestsList: string[]
  }
  export class ExamResultIn {
    BatchId: number;
    OrgGroupId: number;
    TokenKey: string;
    UserId: number;
    SourceTypeId: number;
    SourceId: number;
    TestId: number;
  }
  
  export class ExamResultOut {
    StatusCode: number;
    Message: number;
    TestParticipantResultList: string[]
  }
  //export class TestParticipantResultList {
  //  AcademicId: number;
  //  ClassName: string;
  //  Code: string;
  //  NotAttemptedQuestions: number;
  //  ParticipantType: string;
  //  RightQuestions: number;
  //  RollNo: number;
  //  ScoredMarks: number;
  //  SkippQuestion: number;
  //  SourceId: number;
  //  SourceTypeId: number;
  //  StudentName: string;
  //  TestParticipantId: number;
  //  TotalMarks: number;
  //  TotalQuestions: number;
  //  WrongQuestions: number;
  //}
  