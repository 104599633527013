import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from '../services/navbar.service';
import { ExamResultIn } from '../models/examlist';
import { AppConstants, ExamDetail } from '../constants';
import { ExamListService } from '../services/exam.service';

@Component({
  selector: 'app-result-dashboard',
  templateUrl: './result-dashboard.component.html',
  styleUrls: ['./result-dashboard.component.css'],
})
export class ResultDashboardComponent {
  resultdetail = [];
  AttemptedQsn: number;
  testname = '';
  subjectName = '';

  constructor(private router: Router, public nav: NavbarService,
    private ExamService: ExamListService) {
  }
  ngOnInit() {
    this.getResultData();
    localStorage.removeItem('examFinishDate');
  }

  getResultData() {
    const examResultData = new ExamResultIn();
    examResultData.BatchId = Number(localStorage.getItem("BatchId"));
    examResultData.OrgGroupId = Number(localStorage.getItem("OrgGroupId"));
    examResultData.TokenKey = AppConstants.TokenKey;
    examResultData.UserId = Number(localStorage.getItem('UserId'));
    examResultData.SourceId = Number(localStorage.getItem('UserSourceId'));
    examResultData.SourceTypeId = Number(localStorage.getItem('UserSourceTypeId'));
    examResultData.TestId = Number(localStorage.getItem('TestId')); // this.examDetail.TestId;
    this.ExamService.GetExamResult(examResultData)
      .subscribe(Org => {
        // this.openSubmitResultModalDialog();
        const jsonString = JSON.stringify(Org.TestParticipantResultList);
        this.resultdetail = JSON.parse(jsonString);
        this.testname = ExamDetail.testName;
        this.subjectName = ExamDetail.subject;
        // this.testname = localStorage.getItem('Testname');
        // this.subjectName = localStorage.getItem('SubjectName');
        this.AttemptedQsn = this.resultdetail[0].TotalQuestions - (this.resultdetail[0].NotAttemptedQuestions + this.resultdetail[0].SkippQuestion)
      });
  }

  ngAfterViewInit() {
    console.log("on init after")
  }

  backOnExamDetail() {
    this.router.navigateByUrl('/ExamList');
  }
}
