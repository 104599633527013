import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: '',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent {
  i: number = 0

  constructor(private router: Router, public nav: NavbarService) {
  }
  ngOnInit() {
    //console.log(this.i)
    //let win = (window as any);
    //if (this.i== 0) {
    //  win.location.search = '?loaded';
    //  win.location.reload();
    //  this.i++;
    //  console.log(this.i)

    //}
    localStorage.removeItem('examFinishDate');
    this.nav.show();
  }
  ngAfterViewInit() {
    console.log("on init after")
  }

  GetCompletedExamDetails() {
    this.router.navigate(['/ExamList'], { queryParams: { Status: 'Completed'} });
  }
  GetAssignedExamDetails() {
    this.router.navigate(['/ExamList'], { queryParams: { Status: 'Assigned' } });
  }
}

