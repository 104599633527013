import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginComponent } from '../app/login/login.component';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  href: string;
  IsVisible: boolean;
  subscription: any;
  constructor(public ss: SharedService, private router: Router) {
    this.IsVisible = false;
    this.ss = ss;
    router.events.subscribe((val) => {
      setTimeout(() => {
        window.history.pushState(null, null, window.location.href);
      }, 100);
    });
  }
  ngOnInit() {
    this.subscription = this.ss.getEmittedValue()
      .subscribe(item => this.IsVisible = item);
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    window.history.pushState(null, null, window.location.href);
  }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler(event) {
  //   return false;
  //   // alert('By refreshing this page you may lost all data.');
  // }

}
