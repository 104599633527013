import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavbarService } from '../services/navbar.service';
import { ExamResultIn } from '../models/examlist';
import { AppConstants, ExamDetail } from '../constants';
import { ExamListService } from '../services/exam.service';

@Component({
  selector: 'app-test-remark',
  templateUrl: './test-remark.component.html',
  styleUrls: ['./test-remark.component.css'],
})
export class TestRemarkComponent {
  resultdetail = [];
  AttemptedQsn: number;
  testname = '';
  subjectName = '';
  testStartupRemark = '';
  testId: number;
  testParticipantId: number;
  teststatus: string;

  constructor(private router: Router,
    public nav: NavbarService,
    private route: ActivatedRoute) {
  }
  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.testId = params.TestId;
        this.testParticipantId = params.TestParticipantId;
        this.teststatus = params.Status;
      });
    const testStartupRemark = localStorage.getItem('TestStartupRemark');
    this.testStartupRemark = testStartupRemark.replace(/\n/g, '<br>'); // this.testStartupRemark = testStartupRemark.replaceAll('\n', '<br>');
  }

  gotoTest() {
    this.router.navigate(['/Examdetail'],
      {
        queryParams: {
          TestId: this.testId,
          TestParticipantId: this.testParticipantId,
          Status: this.teststatus
        }
      });
  }

  cancelAndGoBack() {
    this.router.navigate(['/ExamList']);
  }
}
