export class loginadd {
    DeviceUniqueId: string;
    OSTypeId: number;
    OrgId: number;
    SessionId: string;
    hostAddress: string;
    userName: string;
    userPwd: string;
  }
  export class loginout {
    userID: string;
    message: string;
  }
  export class loginIn {
    UserName: string;
    Password: string;
  }
  
  