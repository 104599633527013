import { ChangeDetectorRef, Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppConstants } from '../constants';
import { ExamDetail } from '../constants';

import { NavbarService } from '../services/navbar.service';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isVisible: boolean;
  href: string;
  testname = '';
  subjectname = '';
  testNameSubject = '';
  currentRouterName = '';
  constructor(public nav: NavbarService, private router: Router, private cd: ChangeDetectorRef,
    public sharedService: SharedService) {

  }
  ngOnInit() {
    // this.testname = localStorage.getItem('Testname');
    // this.subjectname = localStorage.getItem('SubjectName');
    this.isVisible = this.nav.visible;
  }
  ngDoCheck() {
    // check for object mutation
    if (ExamDetail.testName && ExamDetail.subject) {
      this.testname = ExamDetail.testName;
      this.subjectname = ExamDetail.subject;
    } else {
      this.testname = localStorage.getItem('Testname');
      this.subjectname = localStorage.getItem('SubjectName');
    }
    this.currentRouterName = this.router.url.split('?')[0];
    console.log(this.currentRouterName);
  }
  isExpanded = false;
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  clearsession() {
    //localStorage.clear();
    this.router.navigate(["/"]);
    //   let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));
    //this.router.navigateByUrl(url);
  }

  backToExamlist() {
    if (!this.sharedService.checkIfUserLoggedIn()) { // recirect back to login page
      this.router.navigate(['']);
      return;
    }
    this.router.navigateByUrl('ExamList');
  }
}
