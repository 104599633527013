import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExamListService } from '../services/exam.service';
import { ExamListIn, ExamResultIn } from '../models/examlist';
import { AppConstants, ExamDetail } from '../constants';
import { SharedService } from '../services/shared.service';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';

@Component({
  selector: '',
  templateUrl: './examlist.component.html',
  styleUrls: ['./examlist.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ExamListComponent implements OnInit {
  displayLoader = false;
  Exams = [];
  availableTestsList = [];
  jsonString: string;
  examData = new ExamListIn;
  examResultData = new ExamResultIn;
  resultdetail = [];
  Status: string;
  StudentName: string;
  Code: string;
  ClassName: string;
  RollNo: string;
  display = 'none'; //default Variable
  displayResultOfSubjective = 'none';
  displayTestTimeModal = 'none';
  TestName: string;
  Subject: string;
  AttemptedQsn: number;
  ExamtypeStatus: string;
  statusId: any;
  fromDateValue = '';
  toDateValue = '';
  constructor(private router: Router, private route: ActivatedRoute,
    private ExamService: ExamListService, public ss: SharedService,
    private datePipe: DatePipe, public sharedService: SharedService) {
    //this.ngOnInit()
    this.ss = ss;
  }
  public ngOnInit() {
    this.ss.show();
    //console.log(this.nav.visible)
    this.route.queryParams
      .subscribe(params => {
        console.log(params); // {order: "popular"}
        if (params.Status == 'Assigned') {
          this.statusId = 1;
          this.Status = 'Assigned';
        } else if (params.Status == 'Started') {
          this.statusId = 2;
          this.Status = 'Started';
        } else if (params.Status == 'Completed') {
          this.statusId = 3;
          this.Status = 'Completed';
        } else if (params.Status == 'Checking') {
          this.statusId = 4;
          this.Status = 'Checking';
        } else {
          this.statusId = 0;
          this.Status = "";
        }
        // set default from and to date filter values
        let fromDate = new Date();
        let toDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 1);
        fromDate.setDate(fromDate.getDate() + 1);
        this.fromDateValue = this.datePipe.transform(fromDate, 'yyyy-MM-dd'); // 2020-12-10
        this.toDateValue = this.datePipe.transform(toDate, 'yyyy-MM-dd'); // 2020-12-10
        this.getExamDetail();
      });
    ExamDetail.testName = '';
    ExamDetail.subject = '';
    // this.Status = "Assigned";
    localStorage.setItem('Testname', '');
    localStorage.setItem('SubjectName', '');
    localStorage.removeItem('examFinishDate');
  }
  getExamDetail() {
    this.displayLoader = true;
    if (!this.sharedService.checkIfUserLoggedIn()) { // recirect back to login page
      this.router.navigate(['']);
      return;
    }
    const examData = new ExamListIn();
    examData.BatchId = +localStorage.getItem("BatchId");
    examData.OrgGroupId = +localStorage.getItem("OrgGroupId");
    examData.TokenKey = AppConstants.TokenKey;
    examData.UserId = +localStorage.getItem('UserId');
    examData.UserSourceId = +localStorage.getItem('UserSourceId');
    examData.UserSourceTypeId = +localStorage.getItem('UserSourceTypeId');
    let fromdatevalueToPass;
    let todatevalueToPass;
    if (this.fromDateValue) {
      fromdatevalueToPass = this.fromDateValue + 'T00:00:00'; // 2020-09-01T00:00:00
      examData.FromDate = fromdatevalueToPass;
    }
    if (this.toDateValue) {
      todatevalueToPass = this.toDateValue + 'T00:00:00'; // 2020-09-01T00:00:00
      examData.ToDate = todatevalueToPass;
    }
    this.ExamService.GetExamList(examData)
      .subscribe(Org => {
        this.displayLoader = false;
        // loads all types of exams initially
        if (Org.AvailableTestsList) {
          const availableTestsList = Org.AvailableTestsList;
          availableTestsList.forEach(element => {
            if (element['FromDate'] !== '1900-01-01T00:00:00' && element['FromTime'] !== '0001-01-01T00:00:00'
              || element['ToDate'] !== '1900-01-01T00:00:00' && element['ToTime'] !== '0001-01-01T00:00:00') {
              element['fromToDateTime'] = this.datePipe.transform(element['FromDate'], 'dd/MM/yyyy')
                + ' ' + this.datePipe.transform(element['FromTime'], 'HH:mm:ss')
                + ' - ' + this.datePipe.transform(element['ToDate'], 'dd/MM/yyyy')
                + ' ' + this.datePipe.transform(element['ToTime'], 'HH:mm:ss');
            } else {
              element['fromToDateTime'] = '';
            }
          });
          this.availableTestsList = Org.AvailableTestsList;
        }
        this.changeStatusValues();
        // initially only show assigned (but not started) exams
        if (this.Status == 'Assigned') {
          this.Exams = this.availableTestsList.filter(e => e['TestStatus'] === 'Not Started');
        } else if (this.Status == 'Started') {
          this.Exams = this.availableTestsList.filter(e => e['TestStatus'] === 'Started');
        } else if (this.Status == 'Completed') {
          this.Exams = this.availableTestsList.filter(e => e['TestStatus'] === 'Completed');
        } else if (this.Status == 'Checking') {
          this.Exams = this.availableTestsList.filter(e => e['TestStatus'] === 'Checking');
        } else {
          this.Exams = this.availableTestsList;
        }
      });
  }
  GetExamResult(exam: any, status) {
    if (!this.sharedService.checkIfUserLoggedIn()) { // recirect back to login page
      this.router.navigate(['']);
      return;
    }
    // GetExamResult(id: string, Testname: string, SubjectName: string, Status: string, TestParticipantId: number, TestStartupRemark: any = null) {
    // check if current date is between exam to and for date
    const examDetail = exam;
    console.log();

    if (status == "Completed") {
      this.displayLoader = true;
      this.examResultData.BatchId = +localStorage.getItem("BatchId");
      this.examResultData.OrgGroupId = +localStorage.getItem("OrgGroupId");
      this.examResultData.TokenKey = AppConstants.TokenKey;
      this.examResultData.UserId = +localStorage.getItem('UserId');
      this.examResultData.SourceId = +localStorage.getItem('UserSourceId');
      this.examResultData.SourceTypeId = +localStorage.getItem('UserSourceTypeId');
      this.examResultData.TestId = +examDetail.TestId;
      this.ExamService.GetExamResult(this.examResultData)
        .subscribe(Org => {
          this.displayLoader = false;
          if (examDetail.TestType == 'Subjective') {
            this.openResultOfSubjectiveModalDialog();
          } else {
            this.openModalDialog();
          }
          this.jsonString = JSON.stringify(Org.TestParticipantResultList);
          this.resultdetail = JSON.parse(this.jsonString);
          this.TestName = examDetail.Test;
          this.Subject = examDetail.SubjectName;
          this.AttemptedQsn = this.resultdetail[0].TotalQuestions - (this.resultdetail[0].NotAttemptedQuestions + this.resultdetail[0].SkippQuestion);
        });
    } else if (status == "Review") {
      ExamDetail.testName = examDetail.Test;
      ExamDetail.subject = examDetail.SubjectName;
      localStorage.setItem('Testname', examDetail.Test);
      localStorage.setItem('SubjectName', examDetail.SubjectName);
      localStorage.setItem('TestStartupRemark', examDetail.TestStartupRemark);
      localStorage.setItem('TestDetails', JSON.stringify(examDetail));
      this.router.navigate(['/Examdetail'], { queryParams: { TestId: examDetail.TestId, TestParticipantId: examDetail.TestParticipantId, Status: status } });
    } else {
      localStorage.setItem('TestStartupRemark', examDetail.TestStartupRemark);
      localStorage.setItem('TestDetails', JSON.stringify(examDetail));
      let fromDate = this.datePipe.transform(exam.FromDate, 'MM/dd/yyyy');
      let fromTime = this.datePipe.transform(exam.FromTime, 'MM/dd/yyyy');
      let toDate = this.datePipe.transform(exam.ToDate, 'MM/dd/yyyy');
      let toTime = this.datePipe.transform(exam.ToTime, 'MM/dd/yyyy');

      let fromDateTime = this.datePipe.transform(exam.FromTime, 'HH:mm');
      let toDateTime = this.datePipe.transform(exam.ToTime, 'HH:mm');

      if (fromDate == '01-01-0001' || fromDate == '01/01/1900'
        || fromDate == '01-01-1900' || fromDate == '01/01/0001') {
        fromDate = null;
      }

      if (fromTime == '01-01-0001' || fromTime == '01/01/1900'
        || fromTime == '01-01-1900' || fromTime == '01/01/0001') {
        fromTime = null;
      }

      if (toDate == '01-01-0001' || toDate == '01/01/1900'
        || toDate == '01-01-1900' || toDate == '01/01/0001') {
        toDate = null;
      }

      if (toTime == '01-01-0001' || toTime == '01/01/1900'
        || toTime == '01-01-1900' || toTime == '01/01/0001') {
        toTime = null;
      }
      // if fromdate and todate is not null (check if current date is between them)
      if (fromDate && toDate) {
        let parameterFromDate = new Date(fromDate + ' ' + fromDateTime);
        let parameterToDate = new Date(toDate + ' ' + toDateTime);
        let todaysDate = new Date();
        if (todaysDate >= parameterFromDate && todaysDate <= parameterToDate) {

        } else {
          this.openDisplayTestTimeModalDialog();
          return;
        }
      }
      ExamDetail.testName = examDetail.Test;
      ExamDetail.subject = examDetail.SubjectName;
      localStorage.setItem('Testname', examDetail.Test);
      localStorage.setItem('SubjectName', examDetail.SubjectName);
      if (examDetail.TestStartupRemark) {
        this.router.navigate(['/testremark'], { queryParams: { TestId: examDetail.TestId, TestParticipantId: examDetail.TestParticipantId, Status: status } });
      } else {
        this.router.navigate(['/Examdetail'], { queryParams: { TestId: examDetail.TestId, TestParticipantId: examDetail.TestParticipantId, Status: status } });
      }
    }
  }
  openModalDialog() {
    this.display = 'block'; //Set block css
  }
  closeModalDialog() {
    this.display = 'none'; //set none css after close dialog
    if (!this.sharedService.checkIfUserLoggedIn()) { // recirect back to login page
      this.router.navigate(['']);
      return;
    }
  }
  openResultOfSubjectiveModalDialog() {
    this.displayResultOfSubjective = 'block'; //Set block css
  }
  closeResultOfSubjectiveModalDialog() {
    this.displayResultOfSubjective = 'none'; //set none css after close dialog
    if (!this.sharedService.checkIfUserLoggedIn()) { // recirect back to login page
      this.router.navigate(['']);
      return;
    }
  }
  openDisplayTestTimeModalDialog() {
    this.displayTestTimeModal = 'block'; //Set block css
  }
  closeDisplayTestTimeModalDialog() {
    this.displayTestTimeModal = 'none'; //set none css after close dialog
  }
  changeStatusValues() {
    if (this.statusId == 1) {
      this.Status = "Assigned";
      // this.Exams = this.availableTestsList.filter(e => e['TestStatus'] === 'Not Started');
    }
    if (this.statusId == 2) {
      this.Status = "Started";
      // this.Exams = this.availableTestsList.filter(e => e['TestStatus'] === 'Started');
    }
    if (this.statusId == 3) {
      this.Status = "Completed";
      // this.Exams = this.availableTestsList.filter(e => e['TestStatus'] === 'Completed');
    }
    if (this.statusId == 4) {
      this.Status = "Checking";
      // this.Exams = this.availableTestsList.filter(e => e['TestStatus'] === 'Checking');
    }
    if (this.statusId == 0) {
      this.Status = "";
    }
  }

  clearSearchCriteria() {
    // set default from and to date filter values
    let fromDate = new Date();
    let toDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 1);
    fromDate.setDate(fromDate.getDate() + 1);
    this.fromDateValue = this.datePipe.transform(fromDate, 'yyyy-MM-dd'); // 2020-12-10
    this.toDateValue = this.datePipe.transform(toDate, 'yyyy-MM-dd'); // 2020-12-10
    this.statusId = 0;
    this.Status = "";
    this.Exams = [];
    // this.fromDateValue = '';
    // this.toDateValue = '';
    // this.statusId = 0;
    // this.Status = "";
    // this.getExamDetail();
  }
}
