import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const taskId = route.data.taskId;
    // let isAuth = this.authService.isAuthenticated();
    let userId = localStorage.getItem('UserId');
    if (!userId) {
      // recirect back to login page
      this.router.navigate(['']);
    } else {
      return true;
    }
  }
}
