import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ExamListIn, ExamListOut, ExamResultIn, ExamResultOut } from '../models/examlist';
import { ExamdetailIn, ExamdetailOut, ExamAnswerIn, ExamAnswerOut, ExamReviewIn, ExamReviewOut } from '../models/examdetail';
import { AppConstants } from '../constants';
@Injectable({
  providedIn: 'root'
})
export class ExamListService {
  BaseUrl: string
  constructor(private http: HttpClient) {
    //this.BaseUrl = 'http://pc10-pc:2500/api/'
    this.BaseUrl = AppConstants.baseURL;
  }
  GetExamList(org: ExamListIn) {
    const baseURL = localStorage.getItem('apihosturl');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': AppConstants.ContentType
      })
    };
    httpOptions.headers =
      httpOptions.headers.set('AuthenticationKey', AppConstants.AuthenticationKey);
    return this.http.post<ExamListOut>(baseURL + 'api/genralassessment/availabletestsv5', org, httpOptions);
  }
  GetExamResult(org: ExamResultIn) {
    const baseURL = localStorage.getItem('apihosturl');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': AppConstants.ContentType,
        'AuthenticationKey': AppConstants.AuthenticationKey
      })
    };
    return this.http.post<ExamResultOut>(baseURL + 'api/genralassessment/testparticipantresult', org, httpOptions);
  }
  GetExamDetail(org: ExamdetailIn) {
    const baseURL = localStorage.getItem('apihosturl');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': AppConstants.ContentType,
        'AuthenticationKey': AppConstants.AuthenticationKey
      })
    };
    // return this.http.post<ExamdetailOut>(baseURL + 'api/genralassessment/testquestion', org, httpOptions);
    return this.http.post<ExamdetailOut>(baseURL + 'api/genralassessment/testquestionv3', org, httpOptions);
  }
  AddanswerAll(org: ExamAnswerIn) {
    const baseURL = localStorage.getItem('apihosturl');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': AppConstants.ContentType,
        'AuthenticationKey': AppConstants.AuthenticationKey
      })
    };
    return this.http.post<ExamAnswerOut>(baseURL + 'api/genralassessment/testparticipantansweradd', org, httpOptions);
  }
  GetReviewDetail(org: ExamReviewIn) {
    const baseURL = localStorage.getItem('apihosturl');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': AppConstants.ContentType,
        'AuthenticationKey': AppConstants.AuthenticationKey
      })
    };
    return this.http.post<ExamReviewOut>(baseURL + 'api/genralassessment/EexamReviewGet', org, httpOptions);
  }
}
