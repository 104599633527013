import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Organization, OrganizationList } from '../models/organization'; 
import { Organizationadd } from '../models/organizationadd'; 
import { loginadd, loginout } from '../models/loginadd';
import { Logindatain, LoginDataOut } from '../models/LoginDataIn'; 
import { Batchadd, BatchList } from '../models/BatchAdd';
import { AppConstants } from '../constants';

//import { Register } from "../app/register";
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  apiRoot: string = "https://httpbin.org";
  BaseUrl:string
  Url: string;
  token: string;
  header: any;
  
  constructor(private http: HttpClient) {

    this.Url = AppConstants.baseURL +'setup/apiget';
    //this.BaseUrl = localStorage.getItem('apihosturl');
    //this.BaseUrl ='http://pc10-pc:2500/api/'
    // this.BaseUrl = AppConstants.baseURL;

  }

  GetOrgId(org: Organizationadd){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': AppConstants.ContentType}) };
    return this.http.post<Organization>(this.Url, org, httpOptions);
  }
  GetOrglist(org: Organizationadd) {
    const baseURL = localStorage.getItem('apihosturl');
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': AppConstants.ContentType}) };
    return this.http.post<OrganizationList>(baseURL+'api/setup/organizationlist', org, httpOptions);
  }
  GetBatchList(org: Batchadd) {
    const baseURL = localStorage.getItem('apihosturl');
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': AppConstants.ContentType }) };
    return this.http.post<BatchList>(baseURL +'api/setup/batchlist', org, httpOptions);
  }
  Login(log: loginadd) {
    const baseURL = localStorage.getItem('apihosturl');
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': AppConstants.ContentType }) };
    return this.http.post<loginout>(baseURL + 'api/baseframework/ValidateUserv2', log, httpOptions);
  }
  LoginData(log: Logindatain) {
    const baseURL = localStorage.getItem('apihosturl');
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': AppConstants.ContentType}) };
    return this.http.post<LoginDataOut>(baseURL + 'api/baseframework/LoggedInUserv2', log, httpOptions);
  }
}  
