export class ExamdetailIn {
    OrgGroupId: number;
    TestId: number;
    TestParticipantId: number;
    TokenKey: string;
    UserId: number;
  }
  export class ExamdetailOut {
    StatusCode: number;
    Message: string;
    TestQuestionList: string [];
    TestQuestionOptionList: string[];
  }
  export class ExamAnswerIn {
    TestId: number;
    TestParticipantId: number;
    QuestionDetailXml: string;
    QuestionOptionDetailXml: string;
    OrgGroupId: number;
    UserId: number;
    TokenKey: string;
  }
  export class ExamAnswerOut {
    StatusCode: number;
    Message: string;
  }
  export class ExamReviewOut {
    StatusCode: number;
    Message: string;
    AvailableTestsReviewList: string[];
    AvailableTestsReviewOptionList: string[];
  }
  export class ExamReviewIn {
    TestId: number;
    TestParticipantId: number;
    TokenKey: string;
  }
  