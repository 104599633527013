export var AppConstants = {
  // public static get baseURL(): string { return "http://pc10-pc:2500/api/"; }
  // public static get ContentType(): string { return "application/json"; }
  // public static get TokenKey(): string { return "NetVidyalaya@123"; }
  // public static get AuthenticationKey(): string { return "/rZppk3krl2GewNPNLUAuYGZcrTwXQ52rGKz6zJuTrs="; }

  baseURL: 'https://erp.onlinevidyalaya.net/api/', // 'https://erp.onlinevidyalaya.net/api/', // 'http://nvlocaltest.sapphire.net/api/', // 'http://nvtest.sapphire.net/api/',
  // baseURL: 'http://nvlocaltest.sapphire.net/api/', // 'http://nvtest.sapphire.net/api/',
  ContentType: 'application/json',
  TokenKey: 'NetVidyalaya@123',
  AuthenticationKey: '/rZppk3krl2GewNPNLUAuYGZcrTwXQ52rGKz6zJuTrs=',
}

export var ExamDetail = {
  testName: '',
  subject: ''
}