import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectorRef, ɵConsole, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExamdetailIn, ExamAnswerIn, ExamReviewIn } from '../models/examdetail';
import { ExamListService } from '../services/exam.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription, Observable, timer, of } from 'rxjs';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { ExamResultIn } from '../models/examlist';
import { AppConstants } from '../constants'
import { SharedService } from '../services/shared.service';


@Component({
  selector: '',
  templateUrl: './examdetail.component.html',
  styleUrls: ['./examdetail.component.css'],
  providers: [DatePipe]
})
export class ExamDetailComponent implements PipeTransform {
  displayLoader = false;
  private subscription: Subscription;
  @Output() TimerExpired: EventEmitter<any> = new EventEmitter<any>();

  @Input() SearchDate: moment.Moment = moment();
  @Input() ElapsTime: number;

  searchEndDate: moment.Moment;
  remainingTime: number;
  minutes: number = 0;
  seconds: number = 0;

  jsonString: string;
  resultdetail = [];
  AttemptedQsn: number;
  isTimeOver = false;

  everySecond: Observable<number> = timer(0, 1000);


  isShown: boolean = false; // hidden by default
  showBtn = 1;
  SkipBtn = 1;
  appState: string;
  questions = [];
  reviewQuestions = [];
  options = [];
  display = 'none'; //default Variable
  displaySubmitConfirmationModal = 'none'; //default Variable
  displayResultModal = 'none';
  displayTimeOverModal = 'none';
  TestId: number;
  TestparticipantId: number;
  Teststatus: string;
  examData = new ExamdetailIn;
  ReviewData = new ExamReviewIn;
  examAdddata = new ExamAnswerIn;
  questionlength: number;
  skipquestions: number[] = [];
  attemptedquestions: number[] = [];
  notattemptedquestions: number = 0;
  isskip: boolean;
  myDate = new Date();
  testdate: string;
  teststarttime: string;
  myendDate = new Date();
  testendtime: string;
  questionxml: string;
  questionoptionxml: string;
  statusId: number = 0;
  testname = '';
  subjectName = '';
  testStartupRemark = '';
  currentFormInstance: any;
  examDetail: any;
  // userForm = new FormGroup({ });

  constructor(private sanitizer: DomSanitizer, private router: Router,
    private route: ActivatedRoute, private ExamService: ExamListService,
    private ref: ChangeDetectorRef, private datePipe: DatePipe,
    public sharedService: SharedService) {
    // this.searchEndDate = this.SearchDate.add(this.ElapsTime, "minutes");
  }

  ngOnInit() {
    // localStorage.openpages = Date.now();
    // var onLocalStorageEvent = function (e) {
    //   if (e.key == "openpages") {
    //     // Listen if anybody else opening the same page!
    //     localStorage.page_available = Date.now();
    //   }
    //   if (e.key == "page_available") {
    //     alert("One more page already open");
    //     // navigate to exam list dashboard page
    //     this.navigateToExamListPage();
    //   }
    // };
    // window.addEventListener('storage', onLocalStorageEvent, false);
    localStorage.openpages = Date.now();
    this.route.queryParams
      .subscribe(params => {
        this.TestId = params.TestId;
        this.TestparticipantId = params.TestParticipantId;
        this.Teststatus = params.Status;
      });
    // this.subscription = this.store
    //   .select('exam')
    //   .subscribe(data => {
    //     console.log(data);
    //   });
    if (this.Teststatus != 'Review') {
      this.getExamDetail();
      const testStartupRemark = localStorage.getItem('TestStartupRemark');
      // this.testStartupRemark = testStartupRemark.replaceAll('\n', '<br>');
      this.testStartupRemark = testStartupRemark.replace(/\n/g, '<br>');
      // if (this.testStartupRemark) {
      //   this.openModalDialog();
      // }
    }
    else {
      this.getReviewDetail();
    }
    this.appState = "hello";
    //this.skipquestions.push(0);
    //console.log(this.skipquestions)
    this.myDate = new Date();
    this.testdate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.teststarttime = this.datePipe.transform(this.myDate, 'HH:mm:ss');
    this.examDetail = JSON.parse(localStorage.getItem('TestDetails'));
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    localStorage.setItem('examFinishDate', this.searchEndDate.toString());
    return false;
    // alert('By refreshing this page you may lost all data.');
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  @HostListener('window:storage', ['$event'])
  onLocalStorageEvent(event) {
    if (event.key == "openpages") {
      // Listen if anybody else is opening the same page!
      localStorage.page_available = Date.now();
    }
    if (event.key == "page_available") {
      alert("One more page already open");
      this.navigateToExamListPage();
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(e) {
    // if (e.shiftKey && e.keyCode == 9) {
    //   console.log('shift and tab');
    // }

    console.log(e);

    if (e.keyCode == 123) {
      console.log('You cannot inspect Element');
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
      console.log('You cannot inspect Element');
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
      console.log('You cannot inspect Element');
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
      console.log('You cannot inspect Element');
      return false;
    }
    if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
      console.log('You cannot inspect Element');
      return false;
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    // const result = window.confirm('There are unsaved changes! Are you sure?');
    // return of(result);
    return false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  navigateToExamListPage() {
    this.router.navigate(['/ExamList']); // window.location = '/Pages/Baseframework/MyDashBoard.aspx';
  }

  getExamDetail() {
    this.displayLoader = true;
    this.examData.OrgGroupId = +localStorage.getItem("OrgGroupId");
    this.examData.TestId = Number(this.TestId);
    this.examData.TestParticipantId = Number(this.TestparticipantId);
    this.examData.TokenKey = AppConstants.TokenKey;
    this.examData.UserId = +localStorage.getItem('UserId');
    this.ExamService.GetExamDetail(this.examData)
      .subscribe(Org => {
        this.displayLoader = false;
        let index = 1;
        Org.TestQuestionList.forEach(element => {
          element['RowIndex'] = index;
          index++;
        });
        this.questions = Org.TestQuestionList;
        this.options = Org.TestQuestionOptionList;
        this.ElapsTime = this.options[0].TestDuration;
        this.questionlength = Org.TestQuestionList.length;

        if (this.ElapsTime > 0) {
          this.remainingTime = 1;
          this.SearchDate = moment();
          if (localStorage.getItem('examFinishDate')) {
            this.searchEndDate = moment(localStorage.getItem('examFinishDate'));
          } else {
            this.searchEndDate = this.SearchDate.add(this.ElapsTime, "minutes");
          }
          // this.searchEndDate = this.SearchDate.add(this.ElapsTime, "minutes");
          // const searchEndDate = this.SearchDate.add(this.ElapsTime, "minutes");
          const alertDuration = Number(this.examDetail.AlertDuration) * 60;

          // store searchEndDate in localstorage, 
          // so if page gets refreshed then also we can be able to finish the test to that old value
          localStorage.setItem('examFinishDate', this.searchEndDate.toString());
          this.subscription = this.everySecond.subscribe((seconds) => {
            var currentTime: moment.Moment = moment();
            this.remainingTime = this.searchEndDate.diff(currentTime);

            this.remainingTime = this.remainingTime / 1000;

            console.log(this.remainingTime);
            // console.log('floor value' + Math.floor(this.remainingTime));
            if (Math.floor(this.remainingTime) == 0) {
              localStorage.removeItem('examFinishDate');
              this.minutes = 0;
              this.seconds = 0;
              this.TimerExpired.emit();
              this.subscription.unsubscribe();
              // disable all the controls and buttons (except finish button)

              this.openTimeOverModalDialog(); // alert('Time Over');

              // check if auto submit flag is true then ask to finish
              this.isTimeOver = true;

              // let isAutoFinishOnTime = this.options[0].IsAutoFinishOnTime;
              // if (isAutoFinishOnTime) {
              //   let element: HTMLElement = document.getElementById('btnFinish') as HTMLElement;
              //   element.click();
              // }
            } else if (Math.floor(this.remainingTime) > 0) {
              // check if remaining time is equal to alert duration set for the exam
              if (Math.floor(this.remainingTime) == alertDuration) {
                alert('Remaining Time ' + Number(this.examDetail.AlertDuration) + ' Minutes');
              };
              this.minutes = Math.floor(this.remainingTime / 60);
              this.seconds = Math.floor(this.remainingTime - this.minutes * 60);
            } else if (Math.floor(this.remainingTime) < 0) {
              localStorage.removeItem('examFinishDate');
              this.minutes = 0;
              this.seconds = 0;
              this.TimerExpired.emit();
              this.subscription.unsubscribe();
              // disable all the controls and buttons (except finish button)

              this.openTimeOverModalDialog(); // alert('Time Over');

              // check if auto submit flag is true then ask to finish
              this.isTimeOver = true;

              // let isAutoFinishOnTime = this.options[0].IsAutoFinishOnTime;
              // if (isAutoFinishOnTime) {
              //   let element: HTMLElement = document.getElementById('btnFinish') as HTMLElement;
              //   element.click();
              // }
            }
            this.ref.markForCheck()
          })
        }
      });
  }
  openModalDialog() {
    this.display = 'block'; //Set block css
    if (!this.sharedService.checkIfUserLoggedIn()) { // recirect back to login page
      this.router.navigate(['']);
      return;
    }
  }
  closeModalDialog() {
    this.display = 'none'; //set none css after close dialog
    if (!this.sharedService.checkIfUserLoggedIn()) { // recirect back to login page
      this.router.navigate(['']);
      return;
    }
  }
  transform(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustStyle(html);
  }
  toggleShow(no: number, form: NgForm) {
    if (!this.sharedService.checkIfUserLoggedIn()) { // recirect back to login page
      this.router.navigate(['']);
      return;
    }
    if (this.Teststatus == 'Review') {
      this.showBtn = no;
    } else {
      if (this.showBtn !== no) {
        if (this.attemptedquestions.indexOf(this.showBtn) == -1) {
          // if previously opened question is not attempted, then also clear that question
          let questionName = 'mode_' + this.questions[this.showBtn - 1].QuestionId;
          form.controls[questionName].setValue('');
        }
      }
      this.showBtn = no;
    }
  }
  nextShow(form: NgForm) {
    if (!this.sharedService.checkIfUserLoggedIn()) { // recirect back to login page
      this.router.navigate(['']);
      return;
    }
    if (this.Teststatus == 'Review') {
      this.showBtn = this.showBtn + 1;
    } else {
      // check if current question is attempted or not (if not then skip functionality)
      let questionName = 'mode_' + this.questions[this.showBtn - 1].QuestionId;
      let questionnumber = this.questions[this.showBtn - 1].RowIndex;
      if (form.controls[questionName].value) {
        this.showBtn = this.showBtn + 1;
        if (this.attemptedquestions.indexOf(questionnumber) == -1) {
          this.attemptedquestions.push(questionnumber);
          this.isskip = false;
          this.skipquestions = this.skipquestions.filter(order => +order !== questionnumber);
        }
      } else {
        this.showBtn = this.showBtn + 1;
      }
    }
  }
  prevShow(form: NgForm) {
    if (!this.sharedService.checkIfUserLoggedIn()) { // recirect back to login page
      this.router.navigate(['']);
      return;
    }
    if (this.Teststatus == 'Review') {
      this.showBtn = this.showBtn - 1;
    } else {
      // check if current question is attempted or not (if not then skip functionality)
      let questionName = 'mode_' + this.questions[this.showBtn - 1].QuestionId;
      let questionnumber = this.questions[this.showBtn - 1].RowIndex;
      if (form.controls[questionName].value) {
        this.showBtn = this.showBtn - 1;
        if (this.attemptedquestions.indexOf(questionnumber) == -1) {
          this.attemptedquestions.push(questionnumber);
          this.isskip = false;
          this.skipquestions = this.skipquestions.filter(order => +order !== questionnumber);
        }
      } else {
        this.showBtn = this.showBtn - 1;
      }
    }
  }
  skipShow(form: NgForm) {
    if (!this.sharedService.checkIfUserLoggedIn()) { // recirect back to login page
      this.router.navigate(['']);
      return;
    }
    // clear the option if any selected
    let questionName = 'mode_' + this.questions[this.showBtn - 1].QuestionId;
    if (form.controls[questionName].value) {
      form.controls[questionName].setValue('');
    }
    if (this.attemptedquestions.indexOf(this.showBtn) !== -1) {
      //this.isskip = false;
      this.attemptedquestions = this.attemptedquestions.filter(order => +order !== this.showBtn);
      this.skipquestions.push(this.showBtn);
      // check if last question then no need to redirect, only skip
      if (this.showBtn !== this.questionlength) {
        this.showBtn = this.showBtn + 1;
      }
      this.isskip = true;
    }
    else if (this.skipquestions.indexOf(this.showBtn) == -1) { // check if not already skipped, then only insert in skipquestions
      this.skipquestions.push(this.showBtn);
      // check if last question then no need to redirect, only skip
      if (this.showBtn !== this.questionlength) {
        this.showBtn = this.showBtn + 1;
      }
      this.isskip = true;
    } else { // else just move the index to the next question
      if (this.showBtn !== this.questionlength) {
        this.showBtn = this.showBtn + 1;
      }
      this.isskip = true;
    }
  }
  clearSelection(form: NgForm) {
    if (!this.sharedService.checkIfUserLoggedIn()) { // recirect back to login page
      this.router.navigate(['']);
      return;
    }
    let questionName = 'mode_' + this.questions[this.showBtn - 1].QuestionId;
    form.controls[questionName].setValue('');
    this.attemptedquestions = this.attemptedquestions.filter(order => +order !== this.showBtn);
    this.skipquestions = this.skipquestions.filter(order => +order !== this.showBtn);
  }
  submit(form: NgForm) {
    let totalScoredMarks = 0;
    let totalMarks = 0;
    let totalQuestions = 0;
    let rightQuestions = 0;
    let wrongQuestions = 0;
    let negativeMarkPerAnswer = 0;
    let testStatusId;

    let strXmlDoc = "<Table>";
    for (let index in this.questions) {
      const currentQuestion = this.questions[index];
      let questionOptionId = form.controls['mode_' + currentQuestion.QuestionId].value;
      let questionOptionKeyId = currentQuestion.QuestionOptionKeyId;

      let scoredmarks = 0;

      totalMarks = totalMarks + Number(currentQuestion.Marks);

      if (this.examDetail.TestType == 'Objective') {
        let optionsForThisQuestion = this.options.filter(x => x.QuestionId == currentQuestion.QuestionId);
        let correctOption = optionsForThisQuestion.filter(x => x.IsKey == true)[0];
        if (Number(questionOptionId) == Number(correctOption['QuestionOptionId'])) {
          totalScoredMarks = totalScoredMarks + Number(currentQuestion.Marks);
          scoredmarks = Number(currentQuestion.Marks);
        }
        if (Number(form.controls['mode_' + currentQuestion.QuestionId].value)
          == Number(correctOption['QuestionOptionId'])) {
          rightQuestions++;
        } else if (this.attemptedquestions.indexOf(currentQuestion.RowIndex) != -1) {
          wrongQuestions++;
        }
        // } else if (currentQuestion.AnswerStatusId != 533 && currentQuestion.AnswerStatusId != 532) {
        //   wrongQuestions++;
        // }
        testStatusId = 545;
      } else {
        testStatusId = 724;
      }

      // if (TestTypeId != 726) {
      //   if (QuestionOptionId == QuestionOptionKeyId)
      //     RightQuestions++;

      //   else {
      //     //WrongQuestions++;
      //     if (QuestionOptionId != QuestionOptionKeyId 
      // && Int16.Parse(DSQuestion.Tables[0].Rows[i]["AnswerStatusId"].ToString()) != 533 
      // && Int16.Parse(DSQuestion.Tables[0].Rows[i]["AnswerStatusId"].ToString()) != 532) {
      //       WrongQuestions++;
      //     }
      //     if (QuestionOptionId > 0) {
      //       if (decimal.Parse(dr_Question[0]["NegativeMarkPerAnswer"].ToString()) > 0) {
      //         NegativeMarkPerAnswer = decimal.Parse(dr_Question[0]["NegativeMarkPerAnswer"].ToString());
      //         TotalScoredMarks = TotalScoredMarks - NegativeMarkPerAnswer;
      //       }
      //     }
      //   }
      // }

      let questionOptionDetail = "QuestionOptionDetail";
      let answerStatusId;
      if (this.attemptedquestions.indexOf(currentQuestion.RowIndex) !== -1) {
        answerStatusId = 531;
      } else if (this.skipquestions.indexOf(currentQuestion.RowIndex) !== -1) {
        answerStatusId = 533;
      } else {
        answerStatusId = 532;
      }
      strXmlDoc = strXmlDoc + "<" + questionOptionDetail;
      strXmlDoc = strXmlDoc + " QuestionId='" + currentQuestion.QuestionId + "'";
      if (this.examDetail.TestType == 'Subjective' && form.controls['mode_' + currentQuestion.QuestionId].value) {
        strXmlDoc = strXmlDoc + " QuestionOptionId='0' ";
        let subjectiveanswer = form.controls['mode_' + currentQuestion.QuestionId].value;
        if (subjectiveanswer) {
          subjectiveanswer = this.replaceSpecialChar(subjectiveanswer);
        }
        strXmlDoc = strXmlDoc + " QuestionAnswer='" + subjectiveanswer + "'";
      } else {
        strXmlDoc = strXmlDoc + " QuestionOptionId='" + form.controls['mode_' + currentQuestion.QuestionId].value + "'";
        strXmlDoc = strXmlDoc + " QuestionAnswer='' ";
      }
      strXmlDoc = strXmlDoc + " ScoredMark='" + scoredmarks + "'";
      strXmlDoc = strXmlDoc + " AnswerStatusId='" + answerStatusId + "'";
      strXmlDoc = strXmlDoc + " OrderIndex='" + currentQuestion.RowIndex + "'";
      strXmlDoc = strXmlDoc + "/>" + "\r\n";

      // if (this.attemptedquestions.indexOf(currentQuestion.RowIndex) !== -1) {
      //   this.statusId = 531;
      //   strXmlDoc += "<QuestionOptionDetail  QuestionId='"
      //     + currentQuestion.QuestionId + "' QuestionOptionId='"
      //     + form.controls['mode_' + currentQuestion.QuestionId].value
      //     + "' ScoredMark='1.00' AnswerStatusId='" + this.statusId
      //     + "' OrderIndex='1' />";
      // }
      // else if (this.skipquestions.indexOf(currentQuestion.RowIndex) !== -1) {
      //   this.statusId = 532;
      //   strXmlDoc += "<QuestionOptionDetail  QuestionId='' ScoredMark='1.00' AnswerStatusId='" + this.statusId + "' OrderIndex='1' />"
      // }
      // else {
      //   this.statusId = 533;
      //   strXmlDoc += "<QuestionOptionDetail  QuestionId='" + currentQuestion.QuestionId + "' QuestionOptionId='' ScoredMark='1.00' AnswerStatusId='" + this.statusId + "' OrderIndex='1' />"
      // }
    };
    strXmlDoc += "</Table>";
    this.notattemptedquestions = this.questionlength - (this.skipquestions.length + this.attemptedquestions.length);
    this.myendDate = new Date();
    this.testendtime = this.datePipe.transform(this.myendDate, 'HH:mm:ss');

    this.examAdddata.OrgGroupId = +localStorage.getItem("OrgGroupId");
    this.examAdddata.TestId = this.TestId;
    this.examAdddata.TestParticipantId = this.TestparticipantId;
    this.examAdddata.TokenKey = AppConstants.TokenKey;
    this.examAdddata.UserId = +localStorage.getItem("UserId");

    let strQuestionXmlDoc = '';
    let questionDetail = 'QuestionDetail';

    strQuestionXmlDoc = strQuestionXmlDoc + "<Table>";
    strQuestionXmlDoc = strQuestionXmlDoc + "<" + questionDetail;
    strQuestionXmlDoc = strQuestionXmlDoc + " StartTime='" + this.teststarttime + "'";
    strQuestionXmlDoc = strQuestionXmlDoc + " EndTime='" + this.testendtime + "'";
    strQuestionXmlDoc = strQuestionXmlDoc + " TestStatusId='" + testStatusId + "'";
    strQuestionXmlDoc = strQuestionXmlDoc + " TotalMarks='" + totalMarks + "'";
    strQuestionXmlDoc = strQuestionXmlDoc + " ScoredMarks='" + totalScoredMarks + "'";
    strQuestionXmlDoc = strQuestionXmlDoc + " TotalQuestions='" + this.questionlength + "'";
    strQuestionXmlDoc = strQuestionXmlDoc + " RightQuestions='" + rightQuestions + "'";
    strQuestionXmlDoc = strQuestionXmlDoc + " WrongQuestions='" + wrongQuestions + "'";
    strQuestionXmlDoc = strQuestionXmlDoc + " SkippQuestions='" + this.skipquestions.length + "'";
    strQuestionXmlDoc = strQuestionXmlDoc + " NotAttemptedQuestions='" + this.notattemptedquestions + "'";
    strQuestionXmlDoc = strQuestionXmlDoc + " /></Table>";

    this.examAdddata.QuestionDetailXml = strQuestionXmlDoc;
    this.examAdddata.QuestionOptionDetailXml = strXmlDoc;
    this.displayLoader = true;
    this.ExamService.AddanswerAll(this.examAdddata)
      .subscribe(final => {
        this.displayLoader = false;
        if (final.Message == 'Success') {
          if (this.examDetail && this.examDetail.TestType == 'Objective' && this.examDetail.IsDisplayResultOnComplete) {
            localStorage.setItem('TestId', this.examDetail.TestId);
            // redirect to exam result dashboard page
            this.router.navigateByUrl('/result-dashboard');
            // get exam result
            // const examResultData = new ExamResultIn();
            // examResultData.BatchId = Number(localStorage.getItem("BatchId"));
            // examResultData.OrgGroupId = Number(localStorage.getItem("OrgGroupId"));
            // examResultData.TokenKey = AppConstants.TokenKey;
            // examResultData.UserId = Number(localStorage.getItem('UserId'));
            // examResultData.SourceId = Number(localStorage.getItem('UserSourceId'));
            // examResultData.SourceTypeId = Number(localStorage.getItem('UserSourceTypeId'));
            // examResultData.TestId = this.examDetail.TestId;

            // this.ExamService.GetExamResult(examResultData)
            //   .subscribe(Org => {
            //     this.openSubmitResultModalDialog();
            //     this.jsonString = JSON.stringify(Org.TestParticipantResultList);
            //     this.resultdetail = JSON.parse(this.jsonString);
            //     this.testname = localStorage.getItem('Testname');
            //     this.subjectName = localStorage.getItem('SubjectName');
            //     this.AttemptedQsn = this.resultdetail[0].TotalQuestions - (this.resultdetail[0].NotAttemptedQuestions + this.resultdetail[0].SkippQuestion)
            //   });
          } else {
            this.router.navigate(['/Dasboard']);
          }
        }
      });
  }
  rdloptionchange(questionnumber: number) {
    if (this.attemptedquestions.indexOf(questionnumber) !== -1) {

    }
    else {
      this.attemptedquestions.push(questionnumber);
      this.isskip = false;
      this.skipquestions = this.skipquestions.filter(order => +order !== questionnumber);
    }
  }
  getReviewDetail() {
    this.ReviewData.TestId = this.TestId;
    this.ReviewData.TestParticipantId = this.TestparticipantId;
    this.ReviewData.TokenKey = AppConstants.TokenKey;
    this.displayLoader = true;
    this.ExamService.GetReviewDetail(this.ReviewData)
      .subscribe(Org => {
        this.displayLoader = false;
        this.questions = Org.AvailableTestsReviewList;
        // this.reviewQuestions = Org.AvailableTestsReviewList;
        this.options = Org.AvailableTestsReviewOptionList;

        // check if attempted question is right or wrong
        this.questions.forEach(que => {
          if (que.AnswerStatus == 'Attempted') {
            const optionsForCurrentQuestion = this.options.filter(x => x.QuestionId == que.QuestionId);
            if (optionsForCurrentQuestion.find(x => x.QuestionStatus == 'Wrong')) {
              que['AnswerStatus'] = 'Wrong';
            } else {
              que['AnswerStatus'] = 'Right';
            }
          }
        });

        //this.ElapsTime = this.options[0].TestDuration
        //console.log("Time:" + this.ElapsTime     
        this.questionlength = Org.AvailableTestsReviewList.length;

        //if (this.ElapsTime > 0) {
        //  this.remainingTime = 1
        //  this.SearchDate = moment();
        //  this.searchEndDate = this.SearchDate.add(this.ElapsTime, "minutes");
        //  this.subscription = this.everySecond.subscribe((seconds) => {
        //    var currentTime: moment.Moment = moment();
        //    this.remainingTime = this.searchEndDate.diff(currentTime)
        //    this.remainingTime = this.remainingTime / 1000;

        //    if (this.remainingTime <= 0) {
        //      console.log('sorry Time is over now')
        //      this.TimerExpired.emit();
        //    }
        //    else {
        //      this.minutes = Math.floor(this.remainingTime / 60);
        //      this.seconds = Math.floor(this.remainingTime - this.minutes * 60);
        //    }
        //    this.ref.markForCheck()
        //  })
        //}
      });


  }

  finishExam(form: NgForm) {
    if (!this.sharedService.checkIfUserLoggedIn()) { // redirect back to login page
      this.router.navigate(['']);
      return;
    }
    this.currentFormInstance = form;
    // check if current question is attempted or not
    let questionName = 'mode_' + this.questions[this.showBtn - 1].QuestionId;
    let questionnumber = this.questions[this.showBtn - 1].RowIndex;

    if (form.controls[questionName].value) {
      if (this.attemptedquestions.indexOf(questionnumber) == -1) {
        this.attemptedquestions.push(questionnumber);
        this.isskip = false;
        this.skipquestions = this.skipquestions.filter(order => +order !== questionnumber);
      }
    } else {
      this.attemptedquestions = this.attemptedquestions.filter(order => +order !== this.showBtn);
    }
    // open confirmation modal
    this.openSubmitConfirmationModalDialog();
  }

  openSubmitConfirmationModalDialog() {
    this.notattemptedquestions = this.questionlength - (this.skipquestions.length + this.attemptedquestions.length);
    this.displaySubmitConfirmationModal = 'block'; //Set block css
  }

  closeSubmitConfirmationModalDialog() {
    this.displaySubmitConfirmationModal = 'none'; //set none css after close dialog
    if (!this.sharedService.checkIfUserLoggedIn()) { // recirect back to login page
      this.router.navigate(['']);
      return;
    }
  }

  openSubmitResultModalDialog() {
    this.displayResultModal = 'block';
  }

  closeSubmitResultModalDialog() {
    this.displayResultModal = 'none';
  }

  openTimeOverModalDialog() {
    this.displayTimeOverModal = 'block';
  }

  closeTimeOverModalDialog() {
    this.displayTimeOverModal = 'none';
    let isAutoFinishOnTime = this.options[0].IsAutoFinishOnTime;
    if (isAutoFinishOnTime) {
      let element: HTMLElement = document.getElementById('btnFinish') as HTMLElement;
      element.click();
    }
  }

  submitTest() {
    if (!this.sharedService.checkIfUserLoggedIn()) { // recirect back to login page
      this.router.navigate(['']);
      return;
    }
    this.submit(this.currentFormInstance);
  }

  replaceSpecialChar(str) {
    str = str.replaceAll("&", "#amp;");
    str = str.replaceAll(">", "#gt;");
    str = str.replaceAll("\"", "#quot;");
    str = str.replaceAll("<", "#lt;");
    str = str.replaceAll("'", "#39;");
    return str;
  }

  preventDraggingQuestion(e) {
    e.preventDefault();
  }
}
