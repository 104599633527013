import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Pipe } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NoSanitizePipe } from './pipe/NoSanitizePipe.pipe';
import { NavbarService } from './services/navbar.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ExamListComponent } from './examlist/examlist.component';
import { ExamDetailComponent } from './examdetail/examdetail.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { ResultDashboardComponent } from './result-dashboard/result-dashboard.component';
import { DatePipe } from '@angular/common';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { CanDeactivateGuard } from './shared/auth/can-deactivate-guard.service';
import { TestRemarkComponent } from './test-remark/test-remark.component';
import { LoaderComponent } from './loader/loader.component';


@NgModule({
  declarations: [
    NoSanitizePipe,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    DashboardComponent,
    LoginComponent,
    ExamListComponent,
    ExamDetailComponent,
    AppComponent,
    NavMenuComponent,
    ResultDashboardComponent,
    TestRemarkComponent,
    LoaderComponent
  ],
  imports: [
    FormsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
   
    ReactiveFormsModule,
    RouterModule.forRoot([
      //{ path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'counter', component: CounterComponent },
      { path: 'fetch-data', component: FetchDataComponent },
      { path: '',
        component: LoginComponent,
        data: {
          title: 'Login Page'
        }
        // canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'Dasboard',
        component: DashboardComponent,
        data: {
          title: 'Dashboard Page'
        }
        // canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'ExamList',
        component: ExamListComponent,
        data: {
          title: 'ExamList'
        },
        canActivate: [AuthGuard]
        // canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'Examdetail',
        component: ExamDetailComponent,
        data: {
          title: 'Examdetail'
        },
        canActivate: [AuthGuard]
        // canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'result-dashboard',
        component: ResultDashboardComponent,
        data: {
          title: 'Result Dashboard'
        },
        canActivate: [AuthGuard]
        // canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'testremark',
        component: TestRemarkComponent,
        data: {
          title: 'TestRemark'
        },
        canActivate: [AuthGuard]
        // canDeactivate: [CanDeactivateGuard]
      },
    ])
    // StoreModule.forRoot({exam: addExamReducer})
  ],
  exports: [NoSanitizePipe],
  providers: [NavbarService, DatePipe, AuthGuard, CanDeactivateGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
