
import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Organization } from '../../app/models/organization';
import { Organizationadd } from '../../app/models/organizationadd';
// import { Batchadd } from '../../app/models/BatchAdd';
import { loginadd, loginIn } from '../models/loginadd';
import { Logindatain, LoginDataOut } from '../models/LoginDataIn';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../services/shared.service';
import { AppConstants } from '../../app/constants';
import { Batchadd } from '../models/BatchAdd';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  displayLoader = false;
  jsonString: string;
  OrgId: number = 1001;
  UserForm: FormGroup;
  IsVisible = false;
  model1 = new loginadd;
  model = new loginIn();
  logdata = new Logindatain;
  logdataout = new LoginDataOut;
  form: FormGroup;
  //userLogin = new UserLogin();
  orders = [];
  batches = [];
  employeeForm: any;
  selectedDay: string = '';
  errorMessage: string;
  //public country: Organizationadd;
  country = new Organizationadd;
  batch = new Batchadd;
  //list = new UserListOut;
  FinalUrls: Observable<Organization[]>;
  Url: string = 'temp';
  ErrorMessage: string
  ipAddress: any;
  submitted: boolean;
  selorg: number;

  orgGroupId: any;
  orgId = 0;
  batchId = 0;

  isQueryParamsPassed = false;

  constructor(private router: Router, private LoginService: LoginService,
    private formBuilder: FormBuilder, private http: HttpClient,
    public sharedService: SharedService, private route: ActivatedRoute) {
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        this.ipAddress = data.ip
      })

    this.form = this.formBuilder.group({
      orders: ['']
    });
    // of(this.getOrders()).subscribe(orders => {
    //   this.orders = orders;
    //   this.form.controls.orders.patchValue(this.orders[0].id);
    // });
  }
  getOrders() {
    return [
      { id: '1', name: 'order 1' },
      { id: '2', name: 'order 2' },
      { id: '3', name: 'order 3' },
      { id: '4', name: 'order 4' }
    ];
  }
  ngOnInit() {
    localStorage.removeItem('UserId');
    this.UserForm = this.formBuilder.group({
      UserName: ['', [Validators.required]],
      Password: ['', [Validators.required]],
      batches: ['', [Validators.required]],
      orders: ['', [Validators.required]],
      OrggroupId: ['', [Validators.required]]
    });
    localStorage.removeItem('examFinishDate');
    this.route.queryParams
      .subscribe(params => {
        // this.batchId = params.BatchId;
        if (params.OrgGroupId && params.OrgId && params.BatchId) {
          // assign organization group id and populate organizations accordingly
          this.orgGroupId = params.OrgGroupId;
          this.country.OrgGroupId = this.orgGroupId;
          this.isQueryParamsPassed = true;
          this.UserForm.controls.OrggroupId.disable();
          this.UserForm.controls.orders.disable();
          this.UserForm.controls.batches.disable();
          this.displayLoader = true;
          this.LoginService.GetOrgId(this.country)
            .subscribe(Org => {
              this.displayLoader = false;
              if (Org.OrgGroupId) {
                this.OrgId = Org.OrgGroupId;
                this.Url = Org.apihosturl;
                localStorage.setItem('apihosturl', Org.apihosturl);
                localStorage.setItem('OrgGroupId', Org.OrgGroupId.toString());
                AppConstants.baseURL = Org.apihosturl;
                this.displayLoader = true;
                this.LoginService.GetOrglist(this.country)
                  .subscribe(org => {
                    this.displayLoader = false;
                    this.jsonString = JSON.stringify(org);
                    this.orders = JSON.parse(this.jsonString);
                    this.orgId = params.OrgId;
                    this.batch['OrgId'] = this.orgId;
                    localStorage.setItem('OrgId', this.batch['OrgId'].toString());
                    this.displayLoader = true;
                    this.LoginService.GetBatchList(this.batch)
                      .subscribe(org => {
                        this.displayLoader = false;
                        this.jsonString = JSON.stringify(org);
                        this.batches = JSON.parse(this.jsonString);
                        this.batchId = params.BatchId;
                        localStorage.setItem('BatchId', this.batchId.toString());
                      });
                  });
                // this.GetOrggroupId(this.country);
                // this.GetOrgList(this.country);
              }
            });
        }
      });
    this.sharedService.change();
    sessionStorage.removeItem('UserName');
    sessionStorage.clear();
    // this.country.OrgGroupId = this.OrgId;
    //this.GetOrggroupId(this.country);
    this.Url = localStorage.getItem('apihosturl');
  }
  get f() { return this.UserForm.controls; }
  onOrgChange() {
    // this.selectedDay = event.target.options[event.target.options.selectedIndex].text;
    this.batch['OrgId'] = this.orgId;
    localStorage.setItem('OrgId', this.batch['OrgId'].toString());
    this.GetBatchList(this.batch);
  }
  selectBatch() {
    localStorage.setItem('BatchId', this.batchId.toString());
  }
  login() {
    this.submitted = true;
    if (this.UserForm.invalid) {
      return;
    }
    else {
      this.model1.DeviceUniqueId = '22222';
      this.model1.hostAddress = this.ipAddress;
      this.model1.OrgId = this.batch['OrgId'];
      this.model1.OSTypeId = 3;
      this.model1.SessionId = 'Web';
      this.model1.userName = this.UserForm.value.UserName;
      this.model1.userPwd = this.UserForm.value.Password;
      this.displayLoader = true;
      this.LoginService.Login(this.model1).subscribe(
        data => {
          this.displayLoader = false;
          if (data.userID != "") {
            localStorage.setItem('UserId', data.userID);
            this.setLoginData();
            //this.router.navigateByUrl('/Dasboard');
          }
          else {
            this.ErrorMessage = data.message
          }
        },
        error => {
          this.displayLoader = false;
          this.ErrorMessage = error.message;
        });
    }
  };
  GetOrggroupId(OrgId: Organizationadd) {
    this.displayLoader = true;
    this.LoginService.GetOrgId(OrgId)
      .subscribe(Org => {
        this.displayLoader = false;
        if (Org.OrgGroupId) {
          this.OrgId = Org.OrgGroupId;
          this.Url = Org.apihosturl;
          localStorage.setItem('apihosturl', Org.apihosturl);
          localStorage.setItem('OrgGroupId', Org.OrgGroupId.toString());
          AppConstants.baseURL = Org.apihosturl;
          this.GetOrgList(this.country);
        } 
        // else if(Number(this.orgGroupId) == Number(OrgId.OrgGroupId)) {
        //   this.orders = [];
        //   this.orgId = 0;
        // }
      });
  };
  GetOrgList(OrgId: Organizationadd) {
    //debugger;
    this.displayLoader = true;
    this.LoginService.GetOrglist(OrgId)
      .subscribe(org => {
        this.displayLoader = false;
        this.jsonString = JSON.stringify(org);
        this.orders = JSON.parse(this.jsonString);
      });
  };
  GetBatchList(OrgId: any) {
    this.displayLoader = true;
    this.LoginService.GetBatchList(OrgId)
      .subscribe(org => {
        this.displayLoader = false;
        this.jsonString = JSON.stringify(org);
        this.batches = JSON.parse(this.jsonString);
      });
  };
  onOrgGroupChange() {
    this.country.OrgGroupId = this.orgGroupId;
    this.GetOrggroupId(this.country);
  };
  setLoginData() {
    this.logdata.OrgId = localStorage.getItem('OrgId');
    this.logdata.BatchId = +localStorage.getItem('BatchId');
    this.logdata.UserId = +localStorage.getItem('UserId');
    this.LoginService.LoginData(this.logdata).subscribe(
      logdata => {
        localStorage.setItem('OrgGroupBatchId', logdata.OrgGroupBatchId.toString());
        localStorage.setItem('OrgGroupId', logdata.OrgGroupId.toString());
        localStorage.setItem('UserIdNo', logdata.UserIdNo.toString());
        localStorage.setItem('UserSourceId', logdata.UserSourceId.toString());
        localStorage.setItem('UserSourceTypeId', logdata.UserSourceTypeId.toString());
        localStorage.setItem('AcademicId', logdata.AcademicId.toString());
        localStorage.setItem('SchoolLogo', logdata.SchoolLogo.toString());
        localStorage.setItem('BatchFromDate', logdata.BatchFromDate.toString());
        localStorage.setItem('BatchToDate', logdata.BatchToDate.toString());
        // save current logged in user's details
        this.router.navigate(['/ExamList/']);
      })
  }
  ngAfterViewInit() {
    // this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'red';
  }
}     
