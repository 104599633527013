export class Logindatain {
  OrgId: string;
  BatchId: number;
  UserId: number;
}
export class LoginDataOut {
  BatchId: string;
  OrgGroupBatchId: number;
  OrgGroupId: number;
  OrgId: number;
  UserId: number;
  UserIdNo: string;
  UserSourceId: number;
  UserSourceTypeId: number;
  SchoolLogo: string;
  AcademicId: number;
  BatchFromDate: string;
  BatchToDate: string;
}
